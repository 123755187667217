import React, { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

// export const getUser = () => {
//   const storedUser = localStorage.getItem("user");

//   return storedUser;
// };

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(
    localStorage.getItem("username", "password")
  );

  const login = (response, username, password) => {
    // Simulate successful authentication (replace with your own logic)
    if (response?.data) {
      //   console.log(response, "login");
      localStorage.setItem("user", JSON.stringify({ username: username }));
      localStorage.setItem("username", JSON.stringify({ username: username }));
      localStorage.setItem("password", JSON.stringify({ password: password }));
      const storedUser = localStorage.getItem("user");
      //   console.log("userrrr : " + storedUser);
      setUser(storedUser);
    } else {
      //   console.log(username, "username", response, "name");
      throw new Error("Invalid credentials");
    }
  };
  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
    localStorage.removeItem("username");
    localStorage.removeItem("password");
  };

  function setupInactivityLogout() {
    let inactivityTimer;

    function startInactivityTimer() {
      inactivityTimer = setTimeout(() => {
        logout();
      }, 1 * 60 * 60 * 1000); // 4 hours in milliseconds
    }

    function resetInactivityTimer() {
      clearTimeout(inactivityTimer);
      startInactivityTimer();
    }

    // Add event listeners to detect user activity.
    document.addEventListener("click", resetInactivityTimer);
    document.addEventListener("mousemove", resetInactivityTimer);
    document.addEventListener("keydown", resetInactivityTimer);

    // const logout = () => {
    //   setUser(null);
    //   localStorage.removeItem("username");
    // };

    // Call startInactivityTimer when the user logs in or interacts with your app.
    startInactivityTimer();
  }

  // Call setupInactivityLogout when your application initializes.
  setupInactivityLogout();

  useEffect(() => {
    const storedUser = localStorage.getItem("user");

    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
